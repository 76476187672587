<template>
    <DataTable
        ref="dt"
        :value="places"
        v-model:selection="selectedItems"
        dataKey="id"
        :paginator="true"
        :rows="5"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 15]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        responsiveLayout="scroll"
    >
        <template #header>
            <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <h5 class="p-m-0">Coordonnées</h5>
                <Button style="margin-right: 20px" label="Ajouter un lieu" icon="pi pi-plus" class="  p-button-raised p-button-success" @click="openAddPlaceForm()" />
            </div>
        </template>
        <Column field="siret" header="Siret">
            <template #body="slotProps">
                <span class="p-column-title">Siret</span>
                <div v-if="!slotProps.data.siret">
                    <Skeleton></Skeleton>
                </div>
                <div v-else>
                    {{ slotProps.data.siret }}
                </div>
            </template>
        </Column>
        <Column field="address" header="Adresse">
            <template #body="slotProps">
                <span class="p-column-title">Adresse</span>
                <div v-if="!slotProps.data.address">
                    <Skeleton></Skeleton>
                </div>
                <div v-else>
                    {{ slotProps.data.address }}
                </div>
            </template>
        </Column>
        <Column field="codePostal" header="E-mail">
            <template #body="slotProps">
                <span class="p-column-title">Code Postal </span>
                <div v-if="!slotProps.data.codePostal">
                    <Skeleton> </Skeleton>
                </div>
                <div v-else>
                    {{ slotProps.data.codePostal }}
                </div>
            </template>
        </Column>
        <Column field="city" header="Ville">
            <template #body="slotProps">
                <span class="p-column-title">Ville</span>
                <div>
                    <div v-if="!slotProps.data.city">
                        <Skeleton></Skeleton>
                    </div>
                    <div v-else>
                        {{ slotProps.data.city }}
                    </div>
                </div>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button icon="far fa-edit" class="p-button p-button-rounded p-component p-button-warning" @click="openEditPlaceForm(slotProps.data.id)"></Button>
                &nbsp;
                <Button icon="pi pi-trash" class="p-button p-component p-button-rounded p-button-danger" @click="confirmDeleteItem(slotProps.data)"></Button>
            </template>
        </Column>
    </DataTable>
    <hr />
    <Dialog v-model:visible="addPlaceForm" :style="{ width: '600px' }" header="+ Adresse" :modal="true" class="p-fluid">
        <form name="company" id="company-formPLace" @submit.prevent="savePlaceForm()" novalidate>
            <div class="p-formgrid p-grid " style="margin-bottom: 30px;">
                <div class="p-fied p-col">
                    <label for="siret">Siret<strong>*</strong></label
                    ><br />
                    <InputText class="mr-3" name="siret" v-model="place.siret" id="siret" autofocus="true" required="true" :class="{ 'p-invalid': v$.place.siret.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.siret.$invalid && isSubmitted) || v$.place.siret.$pending.$response" class="p-error">
                        <small v-if="v$.place.siret.required.$invalid">Veuillez renseigner ce champ.</small>
                        <small v-if="!v$.place.siret.minLength.$invalid">Le Siret doit faire {{ v$.place.siret.minLength.$params.min }} caracteres.</small>
                    </div>
                </div>
                <div class="p-fied p-col">
                    <label for="address">Adresse<strong>*</strong></label
                    ><br />
                    <InputText class="mr-3" name="address" id="address" v-model="place.address" required="true" :class="{ 'p-invalid': v$.place.address.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.address.$invalid && isSubmitted) || v$.place.address.$pending.$response" class="p-error">
                        <small v-if="v$.place.address.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
            </div>
            <div class="p-formgrid p-grid" style="margin-bottom: 50px">
                <div class="p-fied p-col ">
                    <label>Code Postal<strong>*</strong></label>
                    <InputText class="mr-3" name="codePostal" id="codePostal" v-model="place.codePostal" required="true" :class="{ 'p-invalid': v$.place.codePostal.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.codePostal.$invalid && isSubmitted) || v$.place.codePostal.$pending.$response" class="p-error">
                        <small v-if="v$.place.codePostal.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
                <div class="p-fied  p-col ">
                    <label>Ville<strong>*</strong></label>
                    <InputText id="city" type="city" v-model.trim="place.city" :class="{ 'p-invalid': v$.place.city.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.city.$invalid && isSubmitted) || v$.place.city.$pending.$response" class="p-error">
                        <small v-if="v$.place.city.email.$invalid">Adresse Email incorrecte.</small>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Annuler" icon="pi pi-times" class=" p-button-danger p-button-text" @click="hideDialog" />
            <Button label="Ajouter" icon="pi pi-plus" class=" p-button-success " type="submit" form="company-formPLace" />
        </template>
    </Dialog>
    <Dialog v-model:visible="editPlaceForm" :style="{ width: '600px' }" header="+ Adresse" :modal="true" class="p-fluid">
        <form name="company" id="company-formPLace" @submit.prevent="updatePlaceForm()" novalidate>
            <div class="p-formgrid p-grid " style="margin-bottom: 30px;">
                <div class="p-fied p-col">
                    <label for="siret">Siret<strong>*</strong></label
                    ><br />
                    <InputText class="mr-3" name="siret" v-model="place.siret" id="siret" autofocus="true" required="true" :class="{ 'p-invalid': v$.place.siret.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.siret.$invalid && isSubmitted) || v$.place.siret.$pending.$response" class="p-error">
                        <small v-if="v$.place.siret.required.$invalid">Veuillez renseigner ce champ.</small>
                        <small v-if="!v$.place.siret.minLength.$invalid">Le Siret doit faire {{ v$.place.siret.minLength.$params.min }} caracteres.</small>
                    </div>
                </div>
                <div class="p-fied p-col">
                    <label for="address">Adresse<strong>*</strong></label
                    ><br />
                    <InputText class="mr-3" name="address" id="address" v-model="place.address" required="true" :class="{ 'p-invalid': v$.place.address.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.address.$invalid && isSubmitted) || v$.place.address.$pending.$response" class="p-error">
                        <small v-if="v$.place.address.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
            </div>
            <div class="p-formgrid p-grid" style="margin-bottom: 50px">
                <div class="p-fied p-col ">
                    <label>Code Postal<strong>*</strong></label>
                    <InputText class="mr-3" name="codePostal" id="codePostal" v-model="place.codePostal" required="true" :class="{ 'p-invalid': v$.place.codePostal.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.codePostal.$invalid && isSubmitted) || v$.place.codePostal.$pending.$response" class="p-error">
                        <small v-if="v$.place.codePostal.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
                <div class="p-fied  p-col ">
                    <label>Ville<strong>*</strong></label>
                    <InputText id="city" type="city" v-model.trim="place.city" :class="{ 'p-invalid': v$.place.city.$invalid && isSubmitted }" />
                    <div v-if="(v$.place.city.$invalid && isSubmitted) || v$.place.city.$pending.$response" class="p-error">
                        <small v-if="v$.place.city.email.$invalid">Adresse Email incorrecte.</small>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Annuler" icon="pi pi-times" class=" p-button-danger p-button-text" @click="hideDialog" />
            <Button label="Ajouter" icon="pi pi-plus" class=" p-button-success " type="submit" form="company-formPLace" />
        </template>
    </Dialog>
    <!-- BEGIN: DELETE ITEM DIALOG -->
    <Dialog v-model:visible="deleteItemDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="item"
                >Are you sure you want to delete <b>{{ item.type }}</b
                >?</span
            >
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteItem" />
        </template>
    </Dialog>
    <!-- END: DELETE ITEM DIALOG -->
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
    name: 'card-place',

    data() {
        return {
            company: null,
            card: null,
            companies: [],
            place: {},
            places: [],
            selectedItems: null,
            addPlace: false,
            addPlaceForm: false,
            editPlaceForm: false,
            filters: {},
            isSubmitted: false,
            deleteItemDialog: false,
            deleteItemsDialog: false,
            editDialogcompany: false,
            currentPage: 1,
            item: {},
            items: [],
            status: [
                { name: 'Client', code: 'Client' },
                { name: 'Prospect', code: 'Prospect' },
            ],
        };
    },

    props: ['companyPlace'],

    validations() {
        return {
            place: {
                siret: {
                    required,
                    minLength: minLength(14),
                },
                address: { required },
                codePostal: { required },
                city: { required },
            },
        };
    },

    setup: () => ({ v$: useVuelidate() }),
    created() {
        this.reloadData();
    },
    methods: {
        reloadData() {
            this.$resources.places.list(this.currentPage, { company: this.companyPlace.id }).then(response => {
                this.places = response.data;
            });
        },

        openAddPlaceForm() {
            this.addPlaceForm = true;
            this.place = {};
        },
        openEditPlaceForm(id) {
            this.$resources.places.get(id).then(response => {
                this.place = response;
            });
            this.editPlaceForm = true;
            console.log('hey');
        },

        hideDialog() {
            this.editPlaceForm = false;
            this.addPlaceForm = false;
        },
        editDialog() {
            this.editDialogcompany = true;
        },
        savePlaceForm() {
            this.isSubmitted = true;
            this.place.company = this.companyPlace['@id'];
            this.$resources.places.create(this.place).then(data => {
                this.reloadData();
                this.items.push(data);
                this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Adresse ajoutée', life: 3000 });
                this.addPlaceForm = false;
                this.place = {};
            });
        },
        updatePlaceForm() {
            this.$resources.places.update(this.place.id, this.place).then(data => {
                this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Adresse mise à jour', life: 3000 });
                this.items[this.findIndexById(this.place.id)] = data;
                this.reloadData();
                this.editPlaceForm = false;
                this.place = {};
            });
        },
        confirmDeleteItem(item) {
            this.item = item;
            this.deleteItemDialog = true;
        },
        deleteItem() {
            this.$resources.places.delete(this.item.id).then(() => {
                this.items = this.items.filter(val => val.id !== this.item.id);
                this.deleteItemDialog = false;

                this.item = {};

                this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Element supprimé', life: 3000 });
                this.reloadData();
            });
        },

        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}
strong {
    color: red;
}
.detailCompany {
    text-decoration: none;
}

.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
        margin-bottom: 0.25rem;
    }
}
</style>
