<template>
    <DataTable ref="dt" :value="projects" :lazy="true" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="10" :filters="dtFilters"
                :totalRecords="totalItems" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} projects" responsiveLayout="scroll"
                :globalFilterFields="['name']" filterDisplay="row">
        <template #header>
            <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <h5 class="p-m-0">Projets</h5>
                <Button style="margin-right: 20px" label="Ajouter un projet"  icon="pi pi-plus" class="  p-button-raised p-button-success" @click="$refs.projectDialog.openNew()" /> 
            </div>
        </template>
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column field="name" header="Name" filterMatchMode="startsWith" ref="name"> 
            <template #body="slotProps">
                <span v-tooltip.top="slotProps.data.description">{{ slotProps.data.name }}</span>
            </template>
        </Column>
        <Column field="status" header="Status">
            <template #body="slotProps">
                <span :class="'project-badge status-' + slotProps.data.status">{{slotProps.data.status}}</span>
            </template>
        </Column>
        <Column field="startAt" header="Date de démarrage">
            <template #body="slotProps">
                {{ formatDate(slotProps.data.startAt) }}
            </template>
        </Column>
        <Column field="endAt" header="Date de fin" sortable>
            <template #body="slotProps">
                {{ formatDate(slotProps.data.endAt) }}
            </template>
        </Column>
        <Column field="amount" header="Budget"  :style="getUser.isAdmin ? '' : 'display: none;'" >
            <template #body="slotProps">
                {{slotProps.data.total_amount}} &euro;
            </template>
        </Column>
        <Column header="Progression">
            <template #body="slotProps">
                <ProgressBar :value="projectProgress(slotProps.data)" :class="progressColor(projectProgress(slotProps.data))">{{ projectProgress(slotProps.data) }}%</ProgressBar>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="$refs.projectDialog.editProject(slotProps.data)" />
                <router-link :to="{ name: 'projectdetails', props: true, params: { id: slotProps.data.id } }">
                    <Button icon="fas fa-eye" class="p-button-rounded p-button-info" />
                </router-link>
                  &nbsp;
                 <Button icon="pi pi-trash" class="p-button-rounded p-component p-button-danger " @click="openDeleteProjectDialog(slotProps.data.id)"></Button>
            </template>
        </Column>
        <template #expansion="slotProps">
            <div class="orders-subtable">
                <h5 style="display:none;">{{ slotProps.data.name }}</h5>
                <DataTable :value="slotProps.data.items" class="p-datatable-sm" stripedRows>
                    <Column field="type" header="Type"></Column>
                    <Column header="Progression">
                        <template #body="slotProps">
                            <ProgressBar :value="itemProgress(slotProps.data)" :class="progressColor(itemProgress(slotProps.data))">{{ itemProgress(slotProps.data) }}%</ProgressBar>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </template>
    </DataTable>
    <project-dialog ref="projectDialog" @onCreate="projectCreated($event)" @onUpdate="projectUpdated($event)"></project-dialog>
    <Dialog v-model:visible="deleteItemDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="project"
                >Are you sure you want to delete <b>{{ project.name }}</b
                >?</span
            >
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteItem" />
        </template>
    </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ProjectDialog from './ProjectDialog.vue';
import moment from 'moment';


export default {

    name: "projects-list-company",
    data() {
        return {
             company:null,
            projects: [],
            totalItems: 0,
            deleteItemDialog: false,
            project: {},
            expandedRows: [],
            currentPage: 1,
            filters: {
                'order[endAt]': 'desc'
            },
            dtFilters: {
                'name': {value: '', matchMode: 'contains'},
            }
        };
    },

    props:['companyProject'],
    computed: {
        ...mapGetters(['getUser'])
    },
    components: {
        ProjectDialog
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            project: {
                name: { required },
                description: { },
                company: { required },
                status: { },
                startAt: { required },
                endAt:  { required }
            },
        };
    },
    mounted() {
        this.reloadData();
    },
    methods: {
        reloadData() {      
            this.$resources.projects.list(this.currentPage , {company : this.companyProject.id}).then((response) => {this.projects = response.data});           
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
            return '-';
        },
         openDeleteProjectDialog(id) {
             console.log(id);
                this.$resources.projects.get(id).then((response) => {this.project = response});
                this.deleteItemDialog = true;
        },
         deleteItem() {
                this.$resources.projects.delete(this.project.id).then( () => {
                this.$toast.add({severity:'error', summary: 'Suppression', detail: 'Le projet a bien été supprimé', life: 3000});
                this.deleteItemDialog = false;
                this.project = {};
                this.reloadData();
                });
            },
        progressColor(value) {
            return {
                'progress-yellow': value < 50,
                'progress-orange': value > 50 && value < 90,
                'progress-green': value >= 90,
            };
        },
        projectProgress(item) {
            return Math.floor((item.total_imputed_days * 100) / item.total_days);
        },        
        itemProgress(item) {
            return Math.floor((item.total_entry * 100) / item.qty);
        },
        onPage($event) {
            this.currentPage = $event.page + 1;
            this.reloadData();
        },
        onSort($event) {
            this.filters['order[' + $event.sortField + ']'] = $event.sortOrder == 0 ? 'desc' : 'asc';
            this.reloadData();
        },
        projectCreated(project) {
            this.projects.push(project);
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Projet ajouté', life: 3000});
        },
        projectUpdated() {
            this.reloadData();
            this.$toast.add({severity:'success', summary: 'Success Message', detail:'Le projet a bien été modifié !', life: 3000});
        },
    }
};
</script>

<style lang="scss">
.p-datatable .p-datatable-tbody .p-datatable-thead .p-column-title {
    display: block !important;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.formatDesc {
    white-space: pre;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-new {
        background: #afd1fe;
        color: #40518a;
    }

    &.status-progress {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-ended {
        background: #ffcdd2;
        color: #c63737;
    }
}

.p-progressbar.progress-yellow .p-progressbar-value {
    background: #fbc02d !important;
}

.p-progressbar.progress-orange .p-progressbar-value {
    background: #f57c00 !important;
}

.p-progressbar.progress-pink .p-progressbar-value {
    background: #e91e63 !important;
}

.p-progressbar.progress-green .p-progressbar-value {
    background: #4caf50 !important;
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
        margin-bottom: 0.25rem;
    }
}
.display{
    display: none
}

</style>
