<template>
    <!-- BEGIN: CREATE / UPDATE PROJECT DIALOG -->
    <Dialog v-model:visible="projectDialog" :style="{ width: '600px' }" header="Projet" :modal="true" class="p-fluid">
        <form name="projectItem" id="projectItem-form" @submit.prevent="saveItem(!v$.$invalid)" novalidate>
            <div class="p-field">
                <label for="tynamepe">Nom</label>
                <InputText class="mr-3" name="name" id="name" v-model="project.name" required="true" :class="{ 'p-invalid': v$.project.name.$invalid && isSubmitted }" />
                <div v-if="(v$.project.name.$invalid && isSubmitted) || v$.project.name.$pending.$response" class="p-error">
                    <small v-if="v$.project.name.required.$invalid">Veuillez renseigner ce champ.</small>
                </div>
            </div>
            <div class="p-field" v-if="project.id">
                <label for="status">Status</label>
                <Dropdown v-model="project.status" :options="projectStatus" placeholder="Statut du projet" optionLabel="name" optionValue="value" :class="{ 'p-invalid': v$.project.status.$invalid && isSubmitted }" />
                <div v-if="(v$.project.status.$invalid && isSubmitted) || v$.project.status.$pending.$response" class="p-error">
                    <small v-if="v$.project.status.required.$invalid">Veuillez renseigner ce champ.</small>
                </div>
            </div>
            <div class="p-field">
                <label for="company">Client</label>
                <AutoComplete v-model="project.company" :dropdown="true" :suggestions="companies" @complete="searchCompany($event)" field="name" :class="{ 'p-invalid': v$.project.company.$invalid && isSubmitted }" />
                <div v-if="(v$.project.company.$invalid && isSubmitted) || v$.project.company.$pending.$response" class="p-error">
                    <small v-if="v$.project.company.required.$invalid">Veuillez renseigner ce champ.</small>
                </div>
            </div>
            <div class="p-field">
                <label for="description">Description</label>
                <Textarea v-model="project.description" :autoResize="true" rows="5" :class="{ 'p-invalid': v$.project.description.$invalid && isSubmitted }" />
                <div v-if="(v$.project.description.$invalid && isSubmitted) || v$.project.description.$pending.$response" class="p-error">
                    <small v-if="v$.project.description.required.$invalid">Veuillez renseigner ce champ.</small>
                </div>
            </div>
            <div class="p-formgrid p-grid " style="margin-bottom: 20px;">
                <div class="p-field p-col">
                    <label for="startAt">Date début</label>
                    <Calendar v-model="project.startAt" :disabledDays="[0, 6]" :showIcon="true" dateFormat="dd/mm/yy" :class="{ 'p-invalid': v$.project.startAt.$invalid && isSubmitted }" />
                    <div v-if="(v$.project.startAt.$invalid && isSubmitted) || v$.project.startAt.$pending.$response" class="p-error">
                        <small v-if="v$.project.startAt.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
                <div class="p-field p-col">
                    <label for="endAt">Date de fin</label>
                    <Calendar v-model="project.endAt" :disabledDays="[0, 6]" :showIcon="true" dateFormat="dd/mm/yy" :class="{ 'p-invalid': v$.project.endAt.$invalid && isSubmitted }" />
                    <div v-if="(v$.project.endAt.$invalid && isSubmitted) || v$.project.endAt.$pending.$response" class="p-error">
                        <small v-if="v$.project.endAt.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Annuler" icon="pi pi-times" class="p-button-danger p-button-text" @click="hideDialog()" />
            <Button label="Enregistrer" icon="pi pi-check" class="p-button-success" type="submit" form="projectItem-form" />
        </template>
    </Dialog>
    <!-- END: CREATE PROJECT DIALOG -->
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import moment from 'moment';

export default {
    name: 'project-dialog',
    data() {
        return {
            projectDialog: false,
            companies: [],
            project: {},
            projectStatus: [
                { name: 'Nouveau', value: 'new' },
                { name: 'En cours', value: 'progress' },
                { name: 'Terminé', value: 'ended' },
            ],
            isSubmitted: false,
        };
    },
    emits: ['onUpdate', 'onCreate'],
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            project: {
                name: { required },
                description: {required},
                company: { required },
                status: {},
                startAt: { required },
                endAt: { required },
            },
        };
    },
    methods: {
        openNew() {
            this.project = {};
            this.isSubmitted = false;
            this.projectDialog = true;
        },
        hideDialog() {
            this.project = {};
            this.v$.$reset();
            this.projectDialog = false;
            this.isSubmitted = false;
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
            return '-';
        },
        editProject(project) {
            this.project = { ...project };
            this.project.startAt = moment(project.startAt).toDate();
            this.project.endAt = moment(project.endAt).toDate();
            this.projectDialog = true;
        },
        progressColor(value) {
            if (value);
            return {
                'progress-green': value < 50,
                'progress-orange': value >= 50 && value < 90,
                'progress-red': value >= 90,
            };
        },
        progress(item) {
            return Math.floor((item.total_entry * 100) / item.qty);
        },
        searchCompany($event) {
            this.$resources.companies.list(0, { name: $event.query }).then(response => {
                this.companies = response.data;
            });
        },
        saveItem(isValid) {
            this.isSubmitted = true;
            if (!isValid) return;

            let data = {
                name: this.project.name,
                description: this.project.description,
                status: this.project.status || 'new',
                company: this.project.company['@id'],
                startAt: moment(this.project.startAt).format('YYYY-MM-DD'),
                endAt: moment(this.project.endAt).format('YYYY-MM-DD'),
            };

            if (this.project.id) {
                this.$resources.projects.update(this.project.id, data).then(response => {
                    this.hideDialog();
                    this.$emit('onUpdate', response);
                });
            } else {
                this.$resources.projects.create(data).then(response => {
                    this.hideDialog();
                    this.$emit('onCreate', response);
                });
            }
        },
    },
};
</script>

<style lang="scss"></style>
