<template>
<div class="p-grid crud-demo">
    <div class="p-col-12">
        <div class="card" v-if="company">
            <Toast/>
                    <Card style="width: 25rem; margin-bottom: 2em">
                        <template #title>
                            <div v-if="!company.name">
                                <Skeleton></Skeleton>
                            </div>
                            <div v-else>
                                    {{company.name}}
                            </div>
                        </template>
                        <template #content>
                            <p>Siren :   <span v-if="!company.siren"><Skeleton></Skeleton></span>{{company.siren}}</p>
                            <p v-if="(company.email)">Email :  <span v-if="!company.siren"><Skeleton></Skeleton></span> {{company.email}}</p>
                            <p>Status : <span v-if="!company.status"><Skeleton></Skeleton></span>  {{company.status}}</p>
                            <div v-if="company.website">
                                <p><a :href=company.website target="_blank" ><Button label="Site Web" class="p-button  p-button-raised p-component p-button-info " ></Button></a></p>
                            </div>
                            <div v-else>
                                <p><Button style="opacity: 0.2;" disabled label="Site Web" class="p-button p-component p-button-secondary " ></Button></p>
                            </div>
                        </template> 
                        <template #footer>
                                <Button style="margin-right: 20px" label="Modifier"  icon="far fa-edit" class="  p-button-raised p-button-warning" @click="openEditCompany" /> 
                        </template>
                    </Card>
                     <Place :companyPlace="company" v-if="company" ></Place>   
         
                <Dialog v-model:visible="editCompany" :style="{width: '600px'}"  header='Modification' :modal="true" class="p-fluid">
                    <form  name="company" id="company-form"  @submit.prevent="editCompanyForm()" novalidate>
                        <div class="p-formgrid p-grid " style="margin-bottom: 20px;">
                            <div class="p-fied p-col">
                                <label for="siren">Siren<strong>*</strong></label><br>
                                <InputText  class="mr-3" name="siren" v-model="company.siren" id="siren" autofocus="true" required="true" :class="{'p-invalid':v$.company.siren.$invalid && isSubmitted}"/>
                                    <div v-if="(v$.company.siren.$invalid && isSubmitted) || v$.company.siren.$pending.$response" class="p-error">
                                        <small v-if="v$.company.siren.required.$invalid">Veuillez renseigner ce champ.</small>
                                        <small v-if="!v$.company.siren.minLength.$invalid">Le Siren doit faire {{v$.company.siren.minLength.$params.min}} caracteres.</small>
                                    </div>
                            </div>
                            <div class="p-fied p-col">
                                <label for="nom">Nom<strong>*</strong></label><br>
                                <InputText class="mr-3" name="nom" id="nom" v-model="company.name" required="true" :class="{'p-invalid':v$.company.name.$invalid && isSubmitted}"/>
                                    <div v-if="(v$.company.name.$invalid && isSubmitted) || v$.company.name.$pending.$response" class="p-error">
                                        <small v-if="v$.company.name.required.$invalid">Veuillez renseigner ce champ.</small>
                                    </div>
                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-fied p-col">
                                <label>Status<strong>*</strong></label>
                                <Dropdown v-model="company.status" :options="status" optionLabel="name" optionValue="code" :class="{'p-invalid':v$.company.status.$invalid && isSubmitted}"  />
                                    <div v-if="(v$.company.status.$invalid && isSubmitted) || v$.company.status.$pending.$response" class="p-error">
                                        <small v-if="v$.company.status.required.$invalid">Veuillez renseigner ce champ.</small>
                                    </div>
                            </div>
                            <div class="p-fied p-col">
                                <label for="website">Site web</label><br>
                                <InputText class="mr-3" name="website" id="website" v-model="company.website" placeholder="http://"/>
                            </div>
                        </div>
                            <div class="p-field">
                        <label for="sender-email">Email</label>
                            <InputText id="sender-email" v-model.trim="company.email" autofocus :class="{'p-invalid':v$.company.email.$invalid && isSubmitted}" />
                            <div v-if="(v$.company.email.$invalid && isSubmitted) || v$.company.email.$pending.$response" class="p-error">
                                <small v-if="v$.company.email.email.$invalid">Adresse Email incorrecte.</small>
                            </div>
                        </div>
                    </form>
                    <template #footer>
                        <Button label="Annuler"  icon="pi pi-times" class=" p-button-danger p-button-text" @click="hideDialog"/>
                        <Button label="Modifier" icon="pi pi-check" class=" p-button-success" type="submit" form="company-form"/> 
                    </template>
            </Dialog>         
            <contacts-list-company :company="company" v-if="company"></contacts-list-company>              
            <project-list-company :companyProject="company"  v-if="company"></project-list-company>              
        </div>
    </div>
</div>

</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, minLength, email} from '@vuelidate/validators'
import ContactsListCompany from './contact/ContactsListCompany.vue'
import ProjectListCompany from '../project/ProjectListCompany.vue'
import Place from './place/Place.vue'

export default {
    components:{
        ContactsListCompany,
        ProjectListCompany,
        Place 
    },

data() {
    return {
        company: null,
        companies:[],
        contact: {},
        project: {},
        contacts: [],
        editCompany: false,          
        selectedItems: null,
        addPlace:true,
        btnAddPlace:true,
        filters: {},
        isSubmitted: false,
        editDialogcompany: false,
        item:{},
        items: [],
        status:[
            {name:'Client', code:'Client'},
            {name:'Prospect',code:'Prospect'},
        ]
        
        
    }
},
    validations (){
    return { 
        company: {
            siren:{
                required, 
                minLength: minLength(9)
                },
            name:{required},
            status:{required},
                email:{
                email
                },
        },
    }
},
props:{
    id:String
    
},

setup: () => ({ v$: useVuelidate() }),
created() {
    this.reloadData();
},
methods: {
    reloadData() {
        this.$resources.companies.get(this.id).then((response) => {this.company = response});            
    },

    openEditCompany() {
        this.editCompany = true;
    },
    
    openAddPlace() {

        this.btnAddPlace = false;
    },
    
    hideDialog() {
        this.addContact = false;
        this.editCompany = false;
    },
    editDialog(){
            this.editDialogcompany= true     
    },
    editCompanyForm() {
    this.isSubmitted = true;
    this.company.siren = parseInt(this.company.siren)
        if (this.company.id) {
            this.$resources.companies.update(this.company.id, this.company).then( (data) => {
                this.reloadData();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Entreprise mise à jour', life: 3000});
                this.items[this.findIndexById(this.company.id)] = data;
                this.editCompany = false;
                this.editDialogcompany = false;
                this.company = {};
            });
        } else {
            this.$resources.companies.create(this.company).then( (data) => {
                this.reloadData();
                this.items.push(data);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Entreprise ajoutée', life: 3000});

                this.editCompany = false;
                this.company = {};
            }); 
        }
    },
    
    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    },
}
}
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}
strong {
    color: red;
}
.detailCompany{
    text-decoration: none;
}


.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-instock {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-outofstock {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-lowstock {
        background: #FEEDAF;
        color: #8A5340;
    }
}


::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
        margin-bottom: .25rem;
    }
}
</style>
