<template>
<DataTable ref="dt" :value="contacts" v-model:selection="selectedItems" dataKey="id" :paginator="true" :rows="5" :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,15]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
    <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <h5 class="p-m-0">Contacts</h5>
            <Button style="margin-right: 20px" label="Ajouter un contact"  icon="pi pi-plus" class="  p-button-raised p-button-success" @click="addContactDialog()" /> 
        </div>
    </template>
    <Column field="lastName" header="Nom">
        <template #body="slotProps">
            <span class="p-column-title">Nom</span>
                <div v-if="!slotProps.data.lastName">
                    <Skeleton></Skeleton>
                </div>
                <div v-else>
                        {{slotProps.data.lastName}}
                </div>      
        </template>
    </Column>
    <Column field="firstName" header="Prénom">
        <template #body="slotProps">
            <span class="p-column-title">Prénom</span>
                <div v-if="!slotProps.data.firstName">
                        <Skeleton></Skeleton>
                </div>
                <div v-else>
                        {{slotProps.data.firstName}}
                </div>                
        </template>
    </Column>
    <Column field="email" header="E-mail" >
        <template #body="slotProps">
            <span class="p-column-title">E-mail</span>
            <div v-if="!slotProps.data.email">
                <Button label="E-mail"  icon="pi pi-plus" class="p-button p-button-outlined p-component p-button-success " @click="openEditContactDialog(slotProps.data.id)" ></Button>
            </div>
            <div v-else>
                <div v-if="!slotProps.data.email">
                    <Skeleton> </Skeleton>
                </div>
                <div v-else>
                        {{slotProps.data.email}}
                </div>                      
            </div>
        </template>
    </Column>
    <Column field="mobilePhone" header="Portable" >
        <template #body="slotProps">
            <span class="p-column-title">Portable</span>
            <div v-if="!slotProps.data.mobilePhone">
                <Button label="Portable"  icon="pi pi-plus" class="p-button p-button-outlined p-component p-button-success " @click="openEditContactDialog(slotProps.data.id)" ></Button>
            </div>
            <div v-else>
                <div v-if="!slotProps.data.mobilePhone">
                        <Skeleton></Skeleton>
                </div>
                <div v-else>
                        {{slotProps.data.mobilePhone}}
                </div>      
            </div>
        </template>
    </Column>
    <Column field="officePhone" header="Bureau" >
        <template #body="slotProps">
            <span class="p-column-title">Bureau</span>
            <div v-if="!slotProps.data.officePhone">
                <Button label="Bureau"  icon="pi pi-plus" class="p-button p-component p-button-outlined p-button-success " @click="openEditContactDialog(slotProps.data.id)" ></Button>
            </div>
            <div v-else>
                <div v-if="!slotProps.data.officePhone">
                    <Skeleton></Skeleton>
                </div>
                <div v-else>
                        {{slotProps.data.officePhone}}
                </div>          
            </div>
        </template>
    </Column>
    <Column field="status" header="Status" >
        <template #body="slotProps">
            <span class="p-column-title">Status</span>
                    <div v-if="!slotProps.data.status">
                    <Skeleton></Skeleton>
                </div>
                <div v-else>
                    {{slotProps.data.status}}
                </div>
        </template>
    </Column>
    <Column field="companies" header="Entreprise" >
        <template #body="slotProps">
            <span class="p-column-title">Entreprise(s)</span>
            <div v-if="!showCompanies(slotProps.data.companies)">
                <Skeleton></Skeleton>
            </div>
                    <div v-else>
            {{showCompanies(slotProps.data.companies)}}
                    </div>
        </template>
    </Column>
    <Column>
        <template #body="slotProps">
            <div style="display:flex">
                <Button  icon="far fa-edit" class="p-button-rounded p-component p-button-warning " @click="openEditContactDialog(slotProps.data.id)" ></Button>
                &nbsp;
                &nbsp;
                <Button icon="pi pi-trash" class="p-button-rounded p-component p-button-danger " @click="openDeleteContactDialog(slotProps.data.id)"></Button>
            </div>        
            </template>
    </Column>
</DataTable>
<hr>
<Dialog v-model:visible="addContact" :style="{width: '600px'}"  header='+ Nouveau contact' :modal="true" class="p-fluid">
        <form  name="company" id="company-formContact"  @submit.prevent="saveContact(company)" novalidate>
            <div class="p-formgrid p-grid " style="margin-bottom: 30px;">
                <div class="p-fied p-col">
                    <label for="lastName">Nom<strong>*</strong></label><br>
                    <InputText  class="mr-3" name="lastName" v-model="contact.lastName" id="lastName" autofocus="true" required="true" :class="{'p-invalid':v$.contact.lastName.$invalid && isSubmitted}"/>
                        <div v-if="(v$.contact.lastName.$invalid && isSubmitted) || v$.contact.lastName.$pending.$response" class="p-error">
                            <small v-if="v$.contact.lastName.required.$invalid">Veuillez renseigner ce champ.</small>
                        </div>
                </div>
                <div class="p-fied p-col">
                    <label for="firstName">Prénom<strong>*</strong></label><br>
                    <InputText class="mr-3" name="firstName" id="firstName" v-model="contact.firstName" required="true" :class="{'p-invalid':v$.contact.firstName.$invalid && isSubmitted}"/>
                        <div v-if="(v$.contact.firstName.$invalid && isSubmitted) || v$.contact.firstName.$pending.$response" class="p-error">
                            <small v-if="v$.contact.firstName.required.$invalid">Veuillez renseigner ce champ.</small>
                        </div>
                </div>
            </div>
            <div class="p-formgrid p-grid" style="margin-bottom: 50px">
                <div class="p-fied p-col ">
                    <label>Status<strong>*</strong></label>
                    <Dropdown v-model="contact.status" :options="status" optionLabel="name" optionValue="code" :class="{'p-invalid':v$.contact.status.$invalid && isSubmitted}"  />
                    <div v-if="(v$.contact.status.$invalid && isSubmitted) || v$.contact.status.$pending.$response" class="p-error">
                            <small v-if="v$.contact.status.required.$invalid">Veuillez renseigner ce champ.</small>
                        </div>
                </div>
                <div class="p-fied  p-col ">
                    <label>E-mail</label>
                        <InputText id="email" type="email" v-model.trim="contact.email"  :class="{'p-invalid':v$.contact.email.$invalid && isSubmitted}" />
                        <div v-if="(v$.contact.email.$invalid && isSubmitted) || v$.contact.email.$pending.$response" class="p-error">
                            <small v-if="v$.contact.email.email.$invalid">Adresse Email incorrecte.</small>
                        </div>
                </div>
            </div>
            <div class="p-formgrid p-grid" >
                <div class="p-fied p-grid p-col">
                    <label for="phone" class="p-col-2"><svg width="35" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">
                                            <circle cx="150.001" cy="226.085" r="11.718"/>
                                            <path d="M182.691,68.248h-65.382c-3.665,0-6.647,2.843-6.647,6.331v123.592c0,3.491,2.98,6.331,6.647,6.331h65.382     c3.665,0,6.647-2.843,6.647-6.331V74.579C189.336,71.088,186.356,68.248,182.691,68.248z"/>
                                            <path d="M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003     S232.835,0,149.996,0z M208.354,224.021c0,11.458-9.29,20.749-20.749,20.749h-75.214c-11.458,0-20.749-9.29-20.749-20.749V75.323     c0-11.458,9.29-20.749,20.749-20.749h75.214c11.458,0,20.749,9.29,20.749,20.749V224.021z"/>
                                        </svg> </label>
                    <div class="p-col-10">
                            <InputText class="mr-3" name="phone" id="phone" placeholder="Portable" v-model="contact.mobilePhone"/>
                    </div>
                </div>
                <div class="p-fied p-grid p-col">
                    <label for="phone" class="p-col-2"><svg width="35" xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="35" viewBox="0 0 512 512" ><path clip-rule="evenodd" d="m256 .001c141.383 0 256.001 114.614 256.001 256.001 0 141.383-114.619 255.997-256.001 255.997-141.378 0-256.001-114.614-256.001-255.997 0-141.387 114.623-256.001 256.001-256.001zm-20.837 171.407c-.001.001-.001.001-.002.002v43.872l.002.002h130.148c.001-.001.001-.001.002-.002v-43.872c-.001-.001-.001-.001-.002-.002zm170.838 195.974v-222.764c0-5.806-4.722-10.528-10.528-10.528h-197.854c.31 2.375.483 4.792.483 7.246v229.324c0 2.458-.173 4.871-.483 7.249h197.853c5.807.001 10.529-4.721 10.529-10.527zm-229.327 3.279v-229.324c0-19.483-15.851-35.334-35.337-35.334-19.487 0-35.337 15.851-35.337 35.334v229.324c0 19.49 15.851 35.337 35.337 35.337s35.337-15.847 35.337-35.337zm58.49-125.685v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317zm-99.83 53.625v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317z" fill-rule="evenodd"/></svg></label>
                    <div class="p-col-10">
                        <InputText class="mr-3" name="phone" id="phone" placeholder="Bureau" v-model="contact.officePhone"/>
                    </div>
                </div>
            </div>
            <div class="p-field" style="margin-top: 30px" >
                <MultiSelect
                    v-model="contactCompanies"
                    display="chip"
                    placeholder="Ajouter une entreprise"
                    :options="companies"
                    optionLabel="name"
                 
                />
            </div>
        </form>
        <template #footer>
            <Button label="Annuler"  icon="pi pi-times" class=" p-button-danger p-button-text" @click="hideDialog"/>
            <Button label="Ajouter" icon="pi pi-plus" class=" p-button-success "  type="submit" form="company-formContact"/> 
        </template>
</Dialog>
<Dialog v-model:visible="editContact" :style="{width: '600px'}"  header='Modifier contact' :modal="true" class="p-fluid">
        <form  name="company" id="company-formContact"  @submit.prevent="editFormContact()" novalidate>
            <div class="p-formgrid p-grid " style="margin-bottom: 30px;">
                <div class="p-fied p-col">
                    <label for="lastName">Nom<strong>*</strong></label><br>
                    <InputText  class="mr-3" name="lastName" v-model="contact.lastName" id="lastName" autofocus="true" required="true" :class="{'p-invalid':v$.contact.lastName.$invalid && isSubmitted}"/>
                        <div v-if="(v$.contact.lastName.$invalid && isSubmitted) || v$.contact.lastName.$pending.$response" class="p-error">
                            <small v-if="v$.contact.lastName.required.$invalid">Veuillez renseigner ce champ.</small>
                        </div>
                </div>
                <div class="p-fied p-col">
                    <label for="firstName">Prénom<strong>*</strong></label><br>
                    <InputText class="mr-3" name="firstName" id="firstName" v-model="contact.firstName" required="true" :class="{'p-invalid':v$.contact.firstName.$invalid && isSubmitted}"/>
                        <div v-if="(v$.contact.firstName.$invalid && isSubmitted) || v$.contact.firstName.$pending.$response" class="p-error">
                            <small v-if="v$.contact.firstName.required.$invalid">Veuillez renseigner ce champ.</small>
                        </div>
                </div>
            </div>
            <div class="p-formgrid p-grid" style="margin-bottom: 50px">
                <div class="p-fied p-col ">
                    <label>Status<strong>*</strong></label>
                    <Dropdown v-model="contact.status" :options="status" optionLabel="name" optionValue="code" :class="{'p-invalid':v$.contact.status.$invalid && isSubmitted}"  />
                    <div v-if="(v$.contact.status.$invalid && isSubmitted) || v$.contact.status.$pending.$response" class="p-error">
                            <small v-if="v$.contact.status.required.$invalid">Veuillez renseigner ce champ.</small>
                        </div>
                </div>
                <div class="p-fied  p-col ">
                    <label>E-mail</label>
                        <InputText id="email" type="email" v-model.trim="contact.email"  :class="{'p-invalid':v$.contact.email.$invalid && isSubmitted}" />
                        <div v-if="(v$.contact.email.$invalid && isSubmitted) || v$.contact.email.$pending.$response" class="p-error">
                            <small v-if="v$.contact.email.email.$invalid">Adresse Email incorrecte.</small>
                        </div>
                </div>
            </div>
            <div class="p-formgrid p-grid" >
                <div class="p-fied p-grid p-col">
                    <label for="phone" class="p-col-2"><svg width="35" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">
                                            <circle cx="150.001" cy="226.085" r="11.718"/>
                                            <path d="M182.691,68.248h-65.382c-3.665,0-6.647,2.843-6.647,6.331v123.592c0,3.491,2.98,6.331,6.647,6.331h65.382     c3.665,0,6.647-2.843,6.647-6.331V74.579C189.336,71.088,186.356,68.248,182.691,68.248z"/>
                                            <path d="M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003     S232.835,0,149.996,0z M208.354,224.021c0,11.458-9.29,20.749-20.749,20.749h-75.214c-11.458,0-20.749-9.29-20.749-20.749V75.323     c0-11.458,9.29-20.749,20.749-20.749h75.214c11.458,0,20.749,9.29,20.749,20.749V224.021z"/>
                                        </svg> </label>
                    <div class="p-col-10">
                            <InputText class="mr-3" name="phone" id="phone" placeholder="Portable" v-model="contact.mobilePhone"/>
                    </div>
                </div>
                <div class="p-fied p-grid p-col">
                    <label for="phone" class="p-col-2"><svg width="35" xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="35" viewBox="0 0 512 512" ><path clip-rule="evenodd" d="m256 .001c141.383 0 256.001 114.614 256.001 256.001 0 141.383-114.619 255.997-256.001 255.997-141.378 0-256.001-114.614-256.001-255.997 0-141.387 114.623-256.001 256.001-256.001zm-20.837 171.407c-.001.001-.001.001-.002.002v43.872l.002.002h130.148c.001-.001.001-.001.002-.002v-43.872c-.001-.001-.001-.001-.002-.002zm170.838 195.974v-222.764c0-5.806-4.722-10.528-10.528-10.528h-197.854c.31 2.375.483 4.792.483 7.246v229.324c0 2.458-.173 4.871-.483 7.249h197.853c5.807.001 10.529-4.721 10.529-10.527zm-229.327 3.279v-229.324c0-19.483-15.851-35.334-35.337-35.334-19.487 0-35.337 15.851-35.337 35.334v229.324c0 19.49 15.851 35.337 35.337 35.337s35.337-15.847 35.337-35.337zm58.49-125.685v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317zm-99.83 53.625v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317zm49.915 0v30.317h30.317v-30.317z" fill-rule="evenodd"/>7
                    </svg></label>
                    <div class="p-col-10">
                        <InputText class="mr-3" name="phone" id="phone" placeholder="Bureau" v-model="contact.officePhone"/>
                    </div>
                </div>
            </div>
            <div class="p-field" style="margin-top: 30px" >
                <MultiSelect
                    v-model="contact.companies"
                    display="chip"
                    placeholder="Ajouter une entreprise"
                    :options="companies"
                    optionLabel="name"
                 
                />
            </div>
        </form>
        <template #footer>
            <Button label="Annuler"  icon="pi pi-times" class=" p-button-danger p-button-text" @click="hideDialog"/>
            <Button label="Modifier" icon="pi pi-check" class=" p-button-success "  type="submit" form="company-formContact"/> 
        </template>
</Dialog>
<Dialog v-model:visible="deleteItemDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
                <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                    <span v-if="contact"
                        >Are you sure you want to delete <b>{{ contact.lastName }}</b
                        >?</span
                    >
                </div>
                <template #footer>
                    <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false" />
                    <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteItem" />
                </template>
            </Dialog>
</template>

    <script>
    import useVuelidate from '@vuelidate/core'
    import { required, email} from '@vuelidate/validators'
    import MultiSelect from 'primevue/multiselect';

    export default {
        name: "contacts-list-company",
        components:{
            MultiSelect,
        },
    data() {
        return {
            contact: {},
            contacts: [],
            contactCompanies:[],
            editContact: false,
            addContact:false,
            deleteItemDialog:false,
            selectedItems: null,
            filters: {},
            isSubmitted: false,
            editDialogcompany: false,
            item:{},
            items: [],
            currentPage: 1,
            status:[
                {name:'Développeur', code:'Développeur'},
                {name:'Commercial',code:'Commercial'},
                {name:'Comptable',code:'Comptable'},
                {name:'DevOps',code:'DevOps'},
            ]

        }
    },
    validations (){
        return { 
            contact: {
                lastName:{required},
                firstName:{required},
                email:{email},
                status:{required},
                companies:[],
            },

        }
    },
    props:['company'],

    setup: () => ({ v$: useVuelidate() }),
    created() {
        this.reloadData();
    },

    methods: {

            reloadData() {
                    this.$resources.companies.list().then((response) => {this.companies = response.data});
                    this.$resources.contacts.list(this.currentPage, {'companies.name':  this.company.name, 'enable': 1}).then((response) => {this.contacts = response.data});               
            },
            openEditContactDialog(id) {
                this.$resources.contacts.get(id).then((response) => {this.contact = response});
                this.editContact = true;
            },
            openDeleteContactDialog(id) {
                this.$resources.contacts.get(id).then((response) => {this.contact = response});
                this.deleteItemDialog = true;
            },
            deleteItem() {
                this.contact.enable = false;
                 let companies = [];

                 this.contact.companies.forEach(element => {
                     companies.push(element['@id'])
                     var index = this.contact.companies.indexOf(element)
                     this.contact.companies.splice(index, 1)
                 });

                 this.contact.companies = [];

                companies.forEach(element => {
                     this.contact.companies.push(element)
                 });

                this.$resources.contacts.update(this.contact.id, this.contact).then( (data) => {
                this.$toast.add({severity:'error', summary: 'Suppression', detail: 'Le contact à été supprimé', life: 3000});
                this.items[this.findIndexById(this.contact.id)] = data;
                this.deleteItemDialog = false;
                this.contact = {};
                this.reloadData();
                });
            },
            addContactDialog() {
                this.addContact = true;
            },

            hideDialog() {
                this.addContact = false;
                this.editContact = false;
            },
            editDialog(){
                this.editDialogcompany= true     
            },
            saveContact() {
                this.isSubmitted = true;
              
                    this.contact.companies = []
                    this.contact.companies.push(this.company['@id'])
                     for (var i = 0; i < this.contactCompanies.length; i++) {
                        this.contact.companies.push(this.contactCompanies[i]['@id'])
                    }

                    this.$resources.contacts.create(this.contact).then( (data) => {
                    this.reloadData();
                    this.items.push(data);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Contact ajouté', life: 3000});

                    this.addContact = false;
                    this.contact = {};

                });
                
            },
            editFormContact(){
                this.isSubmitted = true;

                for (var i = 0; i < this.contactCompanies.length; i++) {
                this.contact.companies.push(this.contactCompanies[i]['@id'])
                }
                this.$resources.contacts.update(this.contact.id, this.contact).then( (data) => {
                this.reloadData();
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Contact mis à jour', life: 3000});
                this.items[this.findIndexById(this.contact.id)] = data;
                this.editContact = false;
                this.contact = {};
            });

            },
            findIndexById(id) {
                let index = -1;
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].id === id) {
                        index = i;
                        break;
                    }
                }
                return index;
            },
            showCompanies(companies) {
                return companies.map(company => company.name).join(', ');
            }
        }
        }
    </script>

    <style scoped lang="scss">
    .table-header {
    display: flex;
    justify-content: space-between;
    }
    strong {
    color: red;
    }
    .detailCompany{
    text-decoration: none;
    }


    .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
    }

    .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-instock {
    background: #C8E6C9;
    color: #256029;
    }

    &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
    }

    &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
    }
    }

    ::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
    margin-bottom: .25rem;
    }
    }
    </style>